// extracted by mini-css-extract-plugin
export var Window = "window-module--Window--1Njo_";
export var ready = "window-module--ready--HfYkb";
export var active = "window-module--active--1TtA0";
export var closing = "window-module--closing--2H0vO";
export var minimized = "window-module--minimized--2DfXC";
export var Content = "window-module--Content--2xkMi";
export var Bar = "window-module--Bar--2Tija";
export var buttons = "window-module--buttons--UqH4c";
export var Tabs = "window-module--Tabs--2t1-t";
export var Tab = "window-module--Tab--32p4Z";